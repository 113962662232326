<template>
  <div class='col-12 col-lg-6 g c'>
    <br><br><br><br><br><br>
    <div class="card card-body" style="border-radius: 50px">
        <h1 class="text-center">
            تواصل عبر الواتساب على:
            <br><br>
            966580200670
            <br>
        </h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>